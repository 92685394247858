// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/mybanka_logo_white.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./mybankabrand.scss"></require>
  <section id="mybankabrand">
    <a class="brand flex-row">
      <img src="${___HTML_LOADER_IMPORT_0___}">
      <span>\${environment_title}</span>
    </a>
  </section>
</template>
`;
// Exports
export default code;