export class NumberFormatBsvValueConverter {
  toView(value, locale = 'en-US') {
    // Convert satoshis to BSV
    // Convert satoshis to BSV
    // console.log(value)
    let bsv = Number(value);
    if (typeof bsv !== 'number' || isNaN(bsv)) {
      throw new Error('Invalid number format');
    }

    if(value < 0) //already expressed as entire BSV: 0.00 000 001
      bsv *= 1e8;

      bsv = value / 1e8; // epxressed as BSV turning into satoshis

    // If the value is larger than 1,000,000,000,000 satoshis
    if (value > 1e10) {
      console.log("big value")
      // Convert to 'k' format
      let [whole, fraction] = bsv.toFixed(2).split('.');

      // Format the whole part according to the specified locale
      whole = parseInt(whole).toLocaleString(locale);

      // Determine the decimal separator dynamically based on the locale
      const decimalSeparator = (1.1).toLocaleString(locale).substring(1, 2);

      // Determine the currency symbol and its position dynamically based on the locale
      const currencyInfo = (0).toLocaleString(locale, { style: 'currency', currency: 'BSV' }).replace('0', '');
      const currencySymbol = currencyInfo.replace(/[\d\s,.]+/g, ''); // Extract only the currency symbol
      const currencyPosition = currencyInfo.indexOf(currencySymbol);

      // Place the currency symbol on the left or right based on its position and locale convention
      return currencyPosition === 0 || (locale === 'en-US' && currencyPosition === 1)
        ? `${currencySymbol} ${whole}${decimalSeparator}${fraction}k`
        : `${whole}${decimalSeparator}${fraction} ${currencySymbol}k`;
    } else {
      // Split the BSV value into whole and fraction parts
      let [whole, fraction] = bsv.toFixed(8).split('.');

      // Format the whole part according to the specified locale
      whole = parseInt(whole).toLocaleString(locale);

      // Format the fraction part
      fraction = fraction.replace(/(\d{2})(\d{3})(\d{3})/, '$1 $2 $3');

      // Determine the decimal separator dynamically based on the locale
      const decimalSeparator = (1.1).toLocaleString(locale).substring(1, 2);

      // Determine the currency symbol and its position dynamically based on the locale
      const currencyInfo = (0).toLocaleString(locale, { style: 'currency', currency: 'BSV' }).replace('0', '');
      const currencySymbol = currencyInfo.replace(/[\d\s,.]+/g, ''); // Extract only the currency symbol
      const currencyPosition = currencyInfo.indexOf(currencySymbol);

      // Place the currency symbol on the left or right based on its position
      return currencyPosition === 0
        ? `${currencySymbol} ${whole}${decimalSeparator}${fraction}`
        : `${whole}${decimalSeparator}${fraction} ${currencySymbol}`;
    }
  }
}
