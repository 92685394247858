import { IGenericAccount } from "./YapilyModels/IGenericAccount";

export class FriendRequestModel {
  public _id?: string;
  public requester_id_fk: string;
  public friend_id_fk: string;
  public friendAccounts: IGenericAccount;
  public myAccounts: IGenericAccount;
  public status: "requested" | "accepted" | "refused" | "blocked";
  public inviter_recipients?: IGenericAccount[];
  public accepter_recipients?: IGenericAccount[];
  public myWalletName?: [string];
  public hisWalletName?: [string];
  public myAccountsIds: IGenericAccount[];
  public requesterName?: string;
  public hisName: string;
  public requesterProfilePicUrl?: string;
  public receiverName?: string;
  public accepter_id?: string;
  public howManyAccountsShare?: number;
  public email_recipient?: string;
}
