// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
section#transaction-bsv-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  margin: 0px auto;
  padding: 0 1rem;
  width: 100%;
}
section#transaction-bsv-component form.inputs {
  align-self: center;
  width: 100%;
  padding: 1rem;
}
section#transaction-bsv-component .au-stagger {
  animation-delay: 0s;
}
section#transaction-bsv-component .tx_bsv {
  align-items: center;
  border-bottom: 1px solid #3E436D;
  justify-content: space-around;
  height: 4.7rem;
  max-width: 100%;
  padding-bottom: 0rem;
  width: 100%;
}
section#transaction-bsv-component .tx_bsv img {
  border-radius: 50%;
  margin: auto;
  object-fit: cover;
  height: 100%;
  width: 100%;
  min-width: 3rem;
}
section#transaction-bsv-component .tx_bsv div.name_date {
  justify-content: center;
  margin-left: 0.5rem;
  max-height: 3rem;
  text-align: left;
  width: 65%;
}
section#transaction-bsv-component .tx_bsv div.name_date div.sender_date {
  gap: 0.3rem;
}
section#transaction-bsv-component .tx_bsv div.name_date p {
  margin-bottom: 0;
}
section#transaction-bsv-component .tx_bsv div.name_date p.name {
  color: #dfe0e2;
  font-size: 1.1rem;
  font-weight: 500;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  line-height: 1.3rem;
  max-height: 1.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
section#transaction-bsv-component .tx_bsv div.name_date p.date {
  color: #62737F;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Source Sans Pro";
  line-height: 1.2rem;
}
section#transaction-bsv-component .tx_bsv div.amount_status {
  justify-content: center;
  text-align: right;
}
section#transaction-bsv-component .tx_bsv div.amount_status p.amount {
  transition: filter 0.25s;
  min-width: 120px;
}
section#transaction-bsv-component .tx_bsv div.amount_status p {
  margin-bottom: 0;
}
section#transaction-bsv-component div.no_transaction span {
  color: #dfe0e2;
}
section#transaction-bsv-component .gray {
  color: #999ca1;
}
section#transaction-bsv-component p.negative {
  color: #FF2233;
  font-size: 1rem;
  font-family: "Inter";
  font-weight: 400;
  margin: auto 0;
  text-align: right;
  transition: filter 0.25s;
}
section#transaction-bsv-component p.positive {
  color: #31D09A;
  font-size: 1rem;
  font-family: "Inter";
  font-weight: 400;
  margin: auto 0;
  text-align: right;
  transition: filter 0.25s;
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/components/transaction-bsv-component/transaction-bsv-component.scss"],"names":[],"mappings":"AAAA;;;;EAAA;ACEA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,WAAA;AAIF;AAFE;EACE,kBAAA;EAGA,WAAA;EACA,aAAA;AAEJ;AACE;EACE,mBAAA;AACJ;AAEE;EACE,mBAAA;EACA,gCAAA;EACA,6BAAA;EACA,cAAA;EACA,eAAA;EACA,oBAAA;EACA,WAAA;AAAJ;AAEI;EACE,kBAAA;EACA,YAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;AAAN;AAGI;EACE,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,UAAA;AADN;AAGM;EACE,WAAA;AADR;AAIM;EACE,gBAAA;AAFR;AAIM;EACE,cDLY;ECMZ,iBAAA;EACA,gBAAA;EACA,mDDgDqB;EC/CrB,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,uBAAA;AAFR;AAIM;EACE,cDVgB;ECWhB,eAAA;EACA,gBAAA;EACA,8BDoCgB;ECnChB,mBAAA;AAFR;AAMI;EACE,uBAAA;EACA,iBAAA;AAJN;AAMM;EACE,wBAAA;EACA,gBAAA;AAJR;AAOM;EACE,gBAAA;AALR;AAWI;EACE,cDxCc;AC+BpB;AAaE;EACE,cD5Ce;ACiCnB;AAcE;EACE,cD/BmB;ECgCnB,eAAA;EACA,oBDIqB;ECHrB,gBAAA;EACA,cAAA;EACA,iBAAA;EACA,wBAAA;AAZJ;AAeE;EACE,cD1CmB;EC2CnB,eAAA;EACA,oBDNqB;ECOrB,gBAAA;EACA,cAAA;EACA,iBAAA;EACA,wBAAA;AAbJ","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
