import { autoinject, bindable, computedFrom } from "aurelia-framework";
import { YapilyTransactionModel } from "components/models/YapilyModels/YapilyTransactionModel";
import { Router } from "aurelia-router";
import { YapilyTransactionsModel } from "components/models/YapilyModels/YapilyTransactionsModel";
import { SingletonService } from "singleton";
import { RecipientsHttpClient } from "http_clients/RecipientsHttpClient";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { DialogService } from "aurelia-dialog";
import { TransactionsDetails } from "pages/bank/transactions_details/transactions_details";

import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { BitcoinTransactionModel } from "components/models/BitcoinTransactionModel";
import { TransactionsDetailsBitcoin } from "pages/bitcoin/transactions_details_bitcoin/transactions_details_bitcoin";
import { WalletsManager } from "services/WalletManager.service";
import { PaymailWalletClient } from "http_clients/PaymailWalletClient";

@autoinject
export class TransactionBsvComponent {
  @bindable private isSearchEnabled: boolean = false;
  @bindable private txloading: boolean = false;
  @bindable private limit: boolean = false;
  @bindable private transactions: Array<YapilyTransactionModel> = [];
  @bindable private isLoading: boolean = true;
  @bindable private blurred: boolean;
  private transaction_friday = [];
  private transaction_saturday = [];
  private transaction_sunday = [];
  private search_term: string = "";
  private transactions_by_day = [];
  private images;
  private isWallet: boolean = false;
  private my_transaction_bsv: Array<BitcoinTransactionModel> = [];
  private me;

  constructor(
    private router: Router,
    private singleton: SingletonService,
    private recipientHttpClient: RecipientsHttpClient,
    private dialogService: DialogService,
    private paymailWalletHttpClient: PaymailWalletClient,
    private walletManager: WalletsManager,
    private userHttpClient: UsersHttpClients
  ) {}

  async bind() {
    this.me = this.singleton.getMe();
    let outofDevice = this.singleton.getOutOfDeviceWallet();
    if (outofDevice) {
      await this.getTransactionOutOfDeviceBsv(outofDevice);
      return;
    } else {
      await this.getTransactionBsv();
      return;
    }
  }

  async getTransactionOutOfDeviceBsv(outofDevice) {
    console.log(this.singleton.getOutOfDeviceWallet());
    let request = await this.paymailWalletHttpClient.fetch(
      "/transaction?&name=" + outofDevice.name
    );
    let is200Ok = await checkResponseStatus(request);
    this.my_transaction_bsv = (await is200Ok.json()).docs;
    /* await this.getAmountBsv(outofDevice); */
  }

  /*   async getAmountBsv(outofDevice) {
    let request = await this.paymailWalletHttpClient.fetch(
      "/wallets/" + outofDevice._id + "/balance"
    );
    let is200Ok = await checkResponseStatus(request);
    let amount = await is200Ok.json();
    console.log(amount);

  } */

  async getTransactionBsv() {
    const allWallet = await this.walletManager.getWalletsInfos();
    console.log(allWallet);
    const currentWallet =
      allWallet[this.singleton.getCurrentCryptoWalletIndex()];
    console.log("CURRENT WALLET " + JSON.stringify(currentWallet.name));

    let request = await this.paymailWalletHttpClient.fetch(
      "/transaction?&name=" + currentWallet.name
    );
    let is200Ok = await checkResponseStatus(request);
    this.my_transaction_bsv = (
      await is200Ok.json()
    ).docs; /* my_transaction_bsv  */

    this.txloading = false;

    //this.getUsersFromPaymailFromTransactions();
    // console.log("txfrombackend " + JSON.stringify(this.my_transaction_bsv));
    // console.log(this.my_transaction_bsv.docs[0].output_amount[0]);
  }

  // async getUsersFromPaymailFromTransactions() {
  //   console.log("Iterating through txs");
  //   for (let i = 0; i < this.my_transaction_bsv.length; i++) {
  //     console.log(this.my_transaction_bsv[i]);
  //     const sender = await this.paymailWalletHttpClient.fetch(
  //       "/wallets/bsvalias/public-profile/" +
  //         this.my_transaction_bsv[i].senderPaymail
  //     );
  //     console.log(sender);
  //     // const sender = this.userHttpClient.fetch('/profile')
  //   }
  // }

  async goToTransactionDetails(tx_bsv: BitcoinTransactionModel) {
    this.singleton.setSelectedTransactionBitcoin(tx_bsv);
    // this.router.navigateToRoute("transactions_details")
    //Instead let's call the Dialog with the right page
    await this.dialogService
      .open({
        viewModel: TransactionsDetailsBitcoin,
      })
      .whenClosed((response) => {
        if (!response.wasCancelled) {
          //  console.log(response.output);
        } else {
          //was cancelled
        }
        // console.log(response.output);
      });
    // à faire
  }
}
