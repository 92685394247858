// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/currency/bitcoin/bsvfull.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./transaction-bsv-component.scss"></require>
  <require from="../date-format-short"></require>
  <require from="../../components/date-format-full"></require>
  <require from="../ValueConverters/currency-denomination"></require>
  <require from="../ValueConverters/description-cleaner"></require>
  <require from="../ValueConverters/first-letter"></require>
  <require from="../../components/number-format"></require>
  <require from="../../components/number-format-bsv"></require>
  <require from="../../components/loader-ring/loader-ring"></require>
  <require from="../../components/max-length"></require>
  <section id="transaction-bsv-component">
    <form class="inputs" if.bind="isSearchEnabled">
      <div>
        <input value.bind="search_term" type="text" i18n="[placeholder]home.recherche" />
        <i class="fas fa-search"></i>
      </div>
    </form>
    <div class="all_tx flex-column">
      <div class="tx_bsv flex-row" repeat.for="tx_bsv of my_transaction_bsv" click.delegate="goToTransactionDetails(tx_bsv)">
        <div class="around-img flex-column">
          <!-- <img src="/static/img/currency/bitcoin/bsvfull.svg" if.bind="!tx_bsv.senderPaymail"> -->
          <img src="${___HTML_LOADER_IMPORT_0___}">
        </div>
        <div class="name_date flex-column">
          <p class="name" if.bind="!tx_bsv.comment" t="components.bsv_component_transaction.no_comment_provided"></p>
          <p class="name" if.bind="tx_bsv.comment">\${tx_bsv.comment}</p> <!--t="transaction_bsv_component.unknown_sender"-->
          <p class="date gray">\${tx_bsv.createdAt | dateFormat}</p>
          <!-- <div class="sender_date flex-row">
            <p class="name">\${tx_bsv.senderPaymail}</p>
            <p class="name">-</p>
            <p class="name">\${tx_bsv.createdAt | dateFormat}</p>
          </div>
          <p class="date gray" if.bind="!tx_bsv.comment" t="components.bsv_component_transaction.no_comment_provided"></p>
          <p class="date gray" if.bind="tx_bsv.comment">\${tx_bsv.comment}</p> --> <!--t="components.bsv_component_transaction.unknown_sender"-->
        </div>
        <div class="flex-grow"></div>
        <div class="amount_status flex-column"> <!--ancient classBind  class.bind="(blurred) ? 'blurr' : '' "-->
          <p class="amount \${(tx_bsv.output_amount[0] < 0) ? 'negative' : 'positive'} \${(blurred) ? 'blurr': ''}">
            ₿ \${tx_bsv.output_amount[0] >= 0 ? '+' : ''}\${tx_bsv.output_amount[0] | numberFormatBsv:'0.00000000'}</p>
          <p class="status gray">\${tx_bsv.status}</p>
        </div>
      </div>
    </div>
    <div class="no_transaction flex-column" if.bind="my_transaction_bsv.length < 1">
      <span id="no_transaction" i18n="bank.bank_no_transaction"></span>
    </div>
  </section>
</template>
`;
// Exports
export default code;