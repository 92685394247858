import { Router } from "aurelia-router";
import { autoinject } from "aurelia-dependency-injection";
import { SingletonService } from "singleton";
import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { User } from "components/models/UserModel";

@autoinject
export class phoneCountry {
  private country;
  private prefix: string;
  private me: User;
  constructor(
    private router: Router,
    private singleton: SingletonService,
    private userHttpClient: UsersHttpClients
  ) {
    this.country = this.singleton.getPhoneCountry();
    this.me = this.singleton.getMe();
    if (!this.country) {
      this.country = this.me.country;
    }
  }

  getMyFlagFromDb() {}

  goToChooseCountry() {
    this.router.navigateToRoute("choose_phone_country_list");
  }

  getFlag(country: string) {
    switch (country) {
      case "AT":
        this.prefix = "+43";
        return "/img/flags/svg/austria.svg";
        break;
      case "BE":
        this.prefix = "+32";
        return "/img/flags/svg/belgium.svg";
        break;
      case "DN":
        this.prefix = "+45";
        return "/img/flags/svg/denmark.svg";
        break;
      case "ET":
        this.prefix = "+372";
        return "/img/flags/svg/estonia.svg";
        break;
      case "FN":
        this.prefix = "+358";
        return "/img/flags/svg/finland.svg";
        break;
      case "FR":
        this.prefix = "+33";
        return "/img/flags/svg/france.svg";
        break;
      case "DE":
        this.prefix = "+49";
        return "/img/flags/svg/germany.svg";
        break;
      case "ES":
        this.prefix = "+34";
        return "/img/flags/svg/spain.svg";
        break;
      case "EI":
        this.prefix = "+353";
        return "/img/flags/svg/ireland.svg";
        break;
      case "IS":
        this.prefix = "+354";
        return "/img/flags/svg/iceland.svg";
        break;
      case "IT":
        this.prefix = "+39";
        return "/img/flags/svg/italy.svg";
        break;
      case "LA":
        this.prefix = "+371";
        return "/img/flags/svg/latvia.svg";
        break;
      case "LH":
        this.prefix = "+370";
        return "/img/flags/svg/lithuania.svg";
        break;
      case "NL":
        this.prefix = "+31";
        return "/img/flags/svg/netherlands.svg";
        break;
      case "NO":
        this.prefix = "+47";
        return "/img/flags/svg/norway.svg";
        break;
      case "PL":
        this.prefix = "+48";
        return "/img/flags/svg/poland.svg";
        break;
      case "SW":
        this.prefix = "+46";
        return "/img/flags/svg/sweden.svg";
        break;
      case "UK":
        this.prefix = "+44";
        return "/img/flags/svg/uk.svg";
        break;

      default:
        this.prefix = "+32";
        return "/img/flags/svg/belgium.svg";
        break;
    }
  }
}
