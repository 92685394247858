import { Savings_pots } from "./SavingModel";

export class SavingTransactionModel {
    _id: string;
    amount: number;
    bank_id: string;
    account_id: string;
    fk_pot_id: string;
    urlPic: string;

}