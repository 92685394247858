// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
section#mybankabrand {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem 0px;
}
section#mybankabrand a.brand {
  align-items: center;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  font-family: "Inter";
  font-size: 1.3rem !important;
  font-weight: 700;
  height: 2rem !important;
}
section#mybankabrand a.brand span {
  transition: opacity 0.1s ease-in-out;
  opacity: 1 !important;
  color: #FFFFFF !important;
}
section#mybankabrand a.brand img {
  height: 2rem;
  margin-right: 0.75rem;
  transition: margin-right 0.1s ease-out;
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/components/mybankabrand/mybankabrand.scss"],"names":[],"mappings":"AAAA;;;;EAAA;ACEA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,iBAAA;AAIF;AADE;EACE,mBAAA;EAEA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,oBD2FqB;EC1FrB,4BAAA;EACA,gBAAA;EACA,uBAAA;AAEJ;AAAI;EACE,oCAAA;EACA,qBAAA;EACA,yBAAA;AAEN;AACI;EACE,YAAA;EACA,qBAAA;EACA,sCAAA;AACN","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
