export class MenuItem {
  private name: string;
  private url: string;
  private notifications: number = 0;

  constructor(name: string, url, notifications: number = 0) {
    this.name = name;
    this.url = url;
    this.notifications = notifications;
  }
}
