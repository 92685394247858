import { SingletonService } from 'singleton';
import { YapilyTransactionModel } from 'components/models/YapilyModels/YapilyTransactionModel';
import { YapilyBankModel } from 'components/models/YapilyModels/YapilyBankModel';
import { YapilyAccountModel } from 'components/models/YapilyModels/YapilyAccountModel';
import { BankaService } from 'services/banka.service';
import { Router } from 'aurelia-router';
import { AuthService } from 'aurelia-auth';
import { autoinject } from 'aurelia-framework';
import { json } from 'aurelia-fetch-client';
import { bindable, observable } from 'aurelia-framework';
import { BindingEngine } from 'aurelia-framework';
import { Chart, registerables } from 'chart.js';
// init charjs
Chart.register(...registerables);

@autoinject
export class ValidationPopup {
  // * attributes:
  // message.bind = "" on-accept.bind="" on-reject=""
  @bindable private message: string;
  @bindable private onAccept;
  @bindable private onReject;


  accepted() {
    console.log("oui")
    this.onAccept()
  }

  rejected() {
    console.log("non")
    this.onReject()
  }

}
