import { YapilyAccountIdentificationModel } from "./YapilyAccountIdentificationModel";

export class YapilyPayeeModel {
  name: string;
  accountIdentifications: Array<YapilyAccountIdentificationModel>;
  address: {
    country: string;
    // Ajoutez d'autres propriétés d'adresse si nécessaire
  };
  countryCode: string;
}
