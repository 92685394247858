// Module
var code = `<template>
  <require from="./validation-popup.scss"></require>
  <section id="validation-popup" class="flex-column">
    <h4>\${message}</h4>
    <div class="flex-grow"></div>
    <button class="btn btn-secondary" click.delegate="rejected()">Annuler</button>
    <button class="btn btn-primary" click.delegate="accepted()">Confirmer</button>
    <div class="flex-grow"></div>
  </section>
</template>`;
// Exports
export default code;