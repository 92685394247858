import { YapilyAmountModel } from "./YapilyAmountModel";
import { YapilyPayeeModel } from "./YapilyPayeeModel";
import { YapilyPayerModel } from "./YapilyPayerModel";

export class YapilyPaymentRequestModel {
  consent?: string;
  type: string;
  reference: string;
  paymentIdempotencyId: string;
  amount: YapilyAmountModel;
  payee: YapilyPayeeModel;
  payer: YapilyPayerModel;
}