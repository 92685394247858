export class User {
  _id: string;
  codePin: string;
  country: string;
  createdAt: string;
  date_start_subscription: Date;
  displayName: string;
  email: string;
  hasPin: boolean;
  isFirstTime: boolean;
  isSubscribed: boolean;
  paymail: string;
  password: string;
  phone: string;
  role: string;
  updatedAt: Date;
  urlPic: string;
  verification: string;
  verified: boolean;
}
