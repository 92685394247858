export class RecipientModel {
  public _id?: string;
  public account_number?: string;
  public amount?: string;
  public bic?: string;
  public currency?: string;
  public createdAt?: Date;
  public iban: string;
  public identification?: string;
  public isUk?: boolean;
  public isBlockchain?: boolean;
  public name: string;
  public recipient_id?: string;
  public routing_number?: string;
  public source?: string;
  public updatedAt?: Date;
  public urlPic?: string;

  constructor(name?: string) {
    this.name = name;
  }
}
