export class ReportsModel {
  type: string;
  title: string;
  comment: string;
  url: string;
  _id: string
  count: number;
  owner_id: string;
  email?: string;
  createdAt: Date;
}
