// Module
var code = `<template>
  <require from="./phone_country.scss"></require>
  <section id="phone_country" class="flex-row" click.delegate="goToChooseCountry()">
    <img src.bind="getFlag(country)">
    <i class="fas fa-sort-down"></i>
    <p>\${prefix}</p>
  </section>
</template>
`;
// Exports
export default code;