// Module
var code = `<template>
  <require from="./youtubevideo.scss"></require>
  <section id="youtubevideo">
    <!-- <h1>\${message}</h1> -->
    <iframe src="https://www.youtube.com/embed/i0cB7RyRsCw?si=giZCaqpGQ2TwkJyg&amp;controls=1&amp;autoplay=1;start=2" title="YouTube video player" frameborder="0" autoplay allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
  </section>
</template>
`;
// Exports
export default code;