import { autoinject } from 'aurelia-framework';
  
  @autoinject()
  export class Youtubevideo {
    message: string;

    
    constructor() {
      this.message = 'Hello world';
    }
  }
    