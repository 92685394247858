// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
div.navbar_container {
  align-items: flex-start;
  background-color: rgb(15, 19, 25) !important;
  display: flex;
  justify-content: center;
  width: 100%;
}
div.navbar_container nav.navbar {
  align-items: center;
  height: 100%;
  margin: 0px;
  min-width: 40px;
  max-width: 80rem;
  padding: 1rem 1rem;
  width: 100%;
}
div.navbar_container nav.navbar .navbar-toggler {
  border: none !important;
  color: rgb(255, 255, 255) !important;
  margin-right: -0.75rem !important;
}
div.navbar_container nav.navbar .navbar-toggler span {
  font-size: 2rem;
}
div.navbar_container nav.navbar .navbar-brand {
  color: rgb(255, 255, 255) !important;
  gap: 0.5rem;
}
div.navbar_container nav.navbar .navbar-brand img {
  height: 40px !important;
}
div.navbar_container nav.navbar .navbar-brand p {
  align-self: center;
  font-family: "Inter";
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0 !important;
}
div.navbar_container nav.navbar div.navbar-collapse ul.navbar-nav {
  align-items: center;
  margin-left: auto;
  margin-right: 0px;
}
div.navbar_container nav.navbar div.navbar-collapse ul.navbar-nav li {
  margin: 0px 1rem;
  list-style-image: none !important;
}
div.navbar_container nav.navbar div.navbar-collapse ul.navbar-nav li a {
  color: rgb(255, 255, 255) !important;
}
div.navbar_container nav.navbar div.navbar-collapse languages {
  margin-right: 1rem;
}
div.navbar_container nav.navbar div.navbar-collapse languages section#languages div.lang_select_box {
  margin: auto;
}
div.navbar_container nav.navbar .collapse.show languages, div.navbar_container nav.navbar .collapsing languages {
  margin-right: 0 !important;
}
div.navbar_container nav.navbar .collapse.show li, div.navbar_container nav.navbar .collapsing li {
  margin: 0px 0rem;
  padding: 0rem;
}
div.navbar_container nav.navbar .collapse.show div.auth, div.navbar_container nav.navbar .collapsing div.auth {
  flex-direction: column;
  gap: 0.5rem;
}
div.navbar_container nav.navbar .collapse.show div.auth button.register, div.navbar_container nav.navbar .collapsing div.auth button.register {
  margin: 0;
}
div.navbar_container nav.navbar div.auth {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
div.navbar_container nav.navbar div.auth button.register {
  background: #0681b1;
  border: none;
  border-radius: 2rem;
  color: #dfe0e2;
  font-weight: 500;
  margin: 0;
  outline: none;
  padding: 0.5rem 1rem;
}
div.navbar_container nav.navbar div.auth button.signin {
  background: #0f131a;
  border: 1px solid #1F2330;
  border-radius: 2rem;
  font-weight: 500;
  margin: 0;
  margin-right: 0.5rem;
  outline: none;
  padding: 0.5rem 1rem;
}
div.navbar_container nav.navbar div.auth button.signin span {
  color: #dfe0e2;
}
div.navbar_container nav.navbar div.auth div.profile_summary {
  border-radius: 8px;
  cursor: pointer;
  padding: 0.65rem 1rem;
}
div.navbar_container nav.navbar div.auth div.profile_summary img {
  border-radius: 50%;
  height: 1.6rem;
  width: 1.6rem;
}
div.navbar_container nav.navbar div.auth div.profile_summary p {
  align-self: center;
  color: #0681b1 !important;
  font-size: 1rem;
  font-weight: 450;
  margin-bottom: 0;
  margin-left: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/components/nav-bar/nav-bar.scss"],"names":[],"mappings":"AAAA;;;;EAAA;ACEA;EACE,uBAAA;EACA,4CAAA;EACA,aAAA;EACA,uBAAA;EAEA,WAAA;AAGF;AADE;EACE,mBAAA;EAEA,YAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EAEA,WAAA;AACJ;AAEI;EACE,uBAAA;EACA,oCAAA;EACA,iCAAA;AAAN;AAEM;EACE,eAAA;AAAR;AAII;EACE,oCAAA;EACA,WAAA;AAFN;AAIM;EACE,uBAAA;AAFR;AAMM;EACE,kBAAA;EACA,oBD+DiB;EC9DjB,kBAAA;EACA,gBAAA;EACA,2BAAA;AAJR;AAUM;EACE,mBAAA;EACA,iBAAA;EACA,iBAAA;AARR;AAUQ;EACE,gBAAA;EACA,iCAAA;AARV;AAUU;EACE,oCAAA;AARZ;AAqBM;EACE,kBAAA;AAnBR;AAsBU;EACE,YAAA;AApBZ;AA2BM;EACE,0BAAA;AAzBR;AA2BM;EACE,gBAAA;EACA,aAAA;AAzBR;AA2BM;EACE,sBAAA;EACA,WAAA;AAzBR;AA2BQ;EACE,SAAA;AAzBV;AA6BI;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AA3BN;AA6BM;EACE,mBDzEc;EC0Ed,YAAA;EACA,mBAAA;EACA,cD9DY;EC+DZ,gBAAA;EACA,SAAA;EACA,aAAA;EACA,oBAAA;AA3BR;AA6BM;EACE,mBDvEiB;ECwEjB,yBAAA;EACA,mBAAA;EACA,gBAAA;EACA,SAAA;EACA,oBAAA;EACA,aAAA;EACA,oBAAA;AA3BR;AA6BQ;EACE,cD/EU;ACoDpB;AA+BM;EAEE,kBAAA;EACA,eAAA;EACA,qBAAA;AA9BR;AAgCQ;EACE,kBAAA;EACA,cAAA;EACA,aAAA;AA9BV;AAiCQ;EACE,kBAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;AA/BV","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
