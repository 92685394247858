import numeral from "numeral";

export class NumberFormatValueConverter {
  toView(value, format) {
    if (!value && value != 0) return "";
      

    try {
      numeral.register("locale", "fr", {
        delimiters: {
          thousands: " ",
          decimal: ",",
        },
        abbreviations: {
          thousand: "k",  // k for kilo
          million: "m",   // m for mega
          billion: "b",   // g for giga
          trillion: "t",  // t for tera
        },
        ordinal: function (number) {
          return number === 1 ? "er" : "ème";
        },
        currency: {
          symbol: "€",
        },
      });
      numeral.locale("fr");
    } catch (e) {
      numeral.locale("fr");
    }
    return numeral(value).format(format);
  }
}
