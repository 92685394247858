import { YapilyAccountModel } from "./YapilyAccountModel";
import { YapilyBankDetailsModel } from "./YapilyBankDetailsModel";
/* import { YapilyInstitutionModel } from "./YapilyInstitutionModel"; */

export class YapilyBankModel {
  name?: string;
  account_id?: string;
  id?: string
  application_user_id: string;
  consent: string;
  createdAt: Date;
  institution: string;
  institutionDetails?: YapilyBankDetailsModel;
  owner_id: string;
  updatedAt: Date;
  user_uuid: string;
  _id: string;
  accounts?: Array<YapilyAccountModel>;
  consent_id?: string;
  selected?: boolean;
  getName?() {
    return this.institutionDetails.name;
  }
}
