import { autoinject, bindable, computedFrom } from "aurelia-framework";
import { YapilyTransactionModel } from "components/models/YapilyModels/YapilyTransactionModel";
import { Router } from "aurelia-router";
import { YapilyTransactionsModel } from "components/models/YapilyModels/YapilyTransactionsModel";
import { SingletonService } from "singleton";
import { RecipientsHttpClient } from "http_clients/RecipientsHttpClient";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { DialogService } from "aurelia-dialog";
import { TransactionsDetails } from "pages/bank/transactions_details/transactions_details";
import { IGenericTransaction } from "components/models/YapilyModels/IGenericTransaction";
import { connectTo } from "aurelia-store";
import { State } from "state";
import { default as env } from "../../../config/environment.json";


@connectTo()
@autoinject
export class TransactionComponent {
  @bindable private isSearchEnabled: boolean = false;
  @bindable private txloading: boolean = false;
  @bindable private limit: boolean = false;
  @bindable private transactions: Array<{ dateLabel: string, transactions: IGenericTransaction[] }> = [];
  @bindable private isLoading: boolean = true;
  @bindable private blurred: boolean;
  private transaction_friday = [];
  private transaction_saturday = [];
  private transaction_sunday = [];
  private search_term: string = "";
  private transactions_by_day = [];
  private images;
  private isWallet: boolean = false;
  private currentRoute : string;
  private currentRouteBoolean : boolean;
  private environment : string;
  private hasCheckedRoute : boolean;


  constructor(
    private router: Router,
    private singleton: SingletonService,
    private recipientHttpClient: RecipientsHttpClient,
    private dialogService: DialogService,
    private state: State
  ) {
    this.router = router;
    this.environment = env.environment
    this.hasCheckedRoute = false; // Flag to track if we've checked the route
  }

  attached() {
    if (!this.hasCheckedRoute && this.router.currentInstruction) {
      this.currentRoute = this.router.currentInstruction.config.name;
      this.hasCheckedRoute = true; // Set the flag to true after checking
    }
  }
  
  @computedFrom('currentRoute')
  get isCommentPage() {
    return this.currentRoute === 'transaction_comments';
  }

  bind() {
    // Appelez la méthode pour initialiser les transactions après le bind
  }
}


