import { autoinject } from 'aurelia-dependency-injection';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { connectTo } from 'aurelia-store';
import { default as env } from "../../../config/environment.json";


@connectTo()
@autoinject
export class NavBar {

  private welcome_title: string

  isRoot: boolean = window.location.pathname === '/';
  isWelcome: boolean = window.location.pathname === '/welcome' ;


  constructor(
    private i18n: I18N,
    private router: Router, 
    private collapse: boolean
  ){
    this.welcome_title = env.welcome_title
  }

  goToHome() {
    this.router.navigateToRoute("home");
  }


  collpaseFunction(collapse) {
    switch(collapse) {
      case true:
        this.collapse = true;      
        break;
      case false:
        this.collapse = false;
        break;
      default:
      //code block
      }
  }
}



