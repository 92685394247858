export class YapilyBankDetailsModel {
  id: string;
  name: string;
  fullName: string;
  countries: [
    {
      displayName: string;
      countryCode2: string;
    }
  ];
  environmentType: string;
  credentialsType: string;
  media: Array<{
    source: string;
    type: string;
  }>;
  features: string;
}
