const endpoints = {

    // * yapily
    BANKS_AVAILABLE: (country: string) => `/institutions`,
    ALL_BANKS: () => `/active-authorizations`,
    ACCOUNT_AND_TRANSACTIONS: (applicationUserId: string, accountsId: string) => `/accounts/${accountsId}/transactions?applicationUserId=${applicationUserId}`,
    CONSENT: `/consent`,

    // * autre
    RECIPIENT: (recipients_id) => `/recipients/' + ${recipients_id}`
}


/* '/token',
'/consent/',
'/account-auth-requests/',
'/payment-auth-requests/',
'/agreements/enduser/',
'/banks/all', <<<<<<<<< accounts
'/bank/:id',
'/bank/:institution/accounts/all',
'/bank/:institution/account/:account_id/transactions',
'/banks/:country',
'/payments/',
'/payments/:payments',
'/payments/:payments/details',
'/consent/:id',
'/consent/:id',
'/build/link/',
'/links/',
'/accounts/list/:requisition_id',
'/accounts/details/:account_id',
'/accounts/balances/:account_id',
'/accounts/transactions/:account_id', */

export default endpoints;
