import { YapilyAccountNameModel } from "./YapilyAccountNameModel";
import { YapilyAccountIdentificationModel } from "./YapilyAccountIdentificationModel";
import { YapilyAccountBalanceModel } from "./YapilyAccountBalanceModel";
import { YapilyTransactionModel } from "./YapilyTransactionModel";
import { YapilyTransactionsModel } from "./YapilyTransactionsModel";
import { YapilyBankModel } from "./YapilyBankModel";
import { IGenericAccount } from "./IGenericAccount";
import { IGenericTransaction } from "./IGenericTransaction";

export class YapilyAccountModel implements IGenericAccount{
  id: string;
  type: string;
  balance: number;
  cashAccountType: string;
  currency: string;
  identification: string;
  iban: string;
  usageType: string;
  accountType: string;
  institution: string;
  name: string;
  nickname: string;
  picture: string;
  product: string;
  transactions: IGenericTransaction[];
  isBlockchain: boolean;
  accountNames: Array<YapilyAccountNameModel>;
  accountIdentifications: Array<YapilyAccountIdentificationModel>;
  accountBalances: Array<YapilyAccountBalanceModel>;
  // transactions?: YapilyTransactionsModel;
  selected?: boolean
  isError: boolean = false;

  constructor() {
    this.identification = this.iban;
    this.isBlockchain = false;
  }

  /** Si c'est error car consent périmé */
  consentExpired: boolean;
  code: number;
  tracingId: string;
  status: string;
  source: string;
  message: string;

  public getAddress?() {
    return this.iban ?? this.id;
  }
  public getType?() {
    return this.type;
  }
  public getName?() {
    return this.accountNames[0]?.name;
  }
  public getBalance?() {
    return this.balance + this.currency;
  }
}
