// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/logo_white.webp", import.meta.url);
// Module
var code = `<template>
  <require from="./nav-bar.scss"></require>
  <require from="../../components/languages/languages"></require>
  <div class="navbar_container">
    <nav class="navbar navbar-expand-lg navbar-dark "> <!--bg-dark-->
      <!-- <div class="flex-grow hidden_on_mobile"></div> -->
      <a class="navbar-brand flex-row" href="/" router-ignore>
        <img src="${___HTML_LOADER_IMPORT_0___}">
        <p>\${welcome_title}</p></a>
      <!-- <div class="flex-grow hidden_on_desktop"></div> -->
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" click.delegate="collpaseFunction(collapse)">
        <span class="lnr lnr-menu"></span>
      </button>
      <!-- <div class="flex-grow hidden_on_desktop"></div> -->
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item" if.bind="isRoot">
            <a class="nav-link au-target" href="/#header" t="home.title" router-ignore></a>
          </li>
          <li class="nav-item" if.bind="isWelcome">
            <a class="nav-link au-target" href="/welcome#header" t="home.title" router-ignore></a>
          </li>
          <li class="nav-item" if.bind="isRoot">
            <a class="nav-link au-target" href="/#openbanking" t="home.about.title" router-ignore></a>
          </li>
          <li class="nav-item" if.bind="isWelcome">
            <a class="nav-link au-target" href="/welcome#openbanking" t="home.about.title" router-ignore></a>
          </li>
          <li class="nav-item" if.bind="isRoot">
            <a class="nav-link au-target" href="/#feature" t="home.welcome.features.title" router-ignore></a>
          </li>
          <li class="nav-item" if.bind="isWelcome">
            <a class="nav-link au-target" href="/welcome#feature" t="home.welcome.features.title" router-ignore></a>
          </li>
          <li class="nav-item" if.bind="isRoot">
            <a class="nav-link au-target" href="/#pwa" t="home.welcome.install.title" router-ignore></a>
          </li>
          <li class="nav-item" if.bind="isWelcome">
            <a class="nav-link au-target" href="/welcome#pwa" t="home.welcome.install.title" router-ignore></a>
          </li>
          <li class="nav-item">
            <a class="nav-link au-target" href="/blog" t="blog.title"></a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="#contact_page">Contact</a>
          </li> -->
        </ul>
        <div class="flex-grow"></div>
        <div class="auth">
          <languages></languages>
          <button class="signin"  click.delegate="router.navigateToRoute('login')" if.bind="!state.me">
            <span t="auth.login.enter"></span>
          </button>
          <button class="register" t="auth.login.signup_gift" click.delegate="router.navigateToRoute('register')" if.bind="!state.me"><i class="fas fa-gift"></i></button>
          <div class="profile_summary flex-row" if.bind="state.me" click.delegate="goToHome()">
            <img src.bind="state.me.urlPic">
            <p class="profile_displayName">\${state.me.displayName}</p>
          </div>

        </div>
        <!-- <div class="flex-grow"></div> -->
      </div>
    </nav>
  </div>
</template>
`;
// Exports
export default code;