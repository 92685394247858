import { autoinject } from 'aurelia-framework';
import { default as env } from "../../../config/environment.json";
@autoinject()
export class Mybankabrand {
  environment_title: string;

  attached() {
    this.environment_title = env.welcome_title
  }

  bind() {
    this.environment_title = env.welcome_title
  }
  
  constructor() {
    this.environment_title = env.welcome_title
  }
}
